import React, { useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { ValidationError } from '../../components'; // Import ValidationError component
import css from './GoogleMapsAutocomplete.module.css';

const GoogleMapsAutocompleteInput = ({
  onPlaceSelected,
  onChange,
  id,
  meta = {},
  input,
  label,
  placeholder = 'Enter your city', // Default placeholder
  options = { types: ['(cities)'] }, // Default options to show cities only
}) => {
  const [inputValue, setInputValue] = useState('');
  const [placeSelected, setPlaceSelected] = useState(false); // Track if a place has been selected

  const { touched, error } = meta; // Destructure meta to avoid undefined errors

  // Handle place selection
  const handlePlaceSelected = place => {
    const address = place.formatted_address;
    setInputValue(address); // Set input value to selected place
    onPlaceSelected(address); // Pass the valid place to the parent (form)
    input.onChange(address); // Ensure the form state updates immediately on place selection
    setPlaceSelected(true); // Mark that a valid place has been selected
  };

  // Handle clear button
  const handleClear = () => {
    setInputValue(''); // Clear local input value
    onChange(''); // Clear the form field value
    input.onChange(''); // Ensure form state is cleared immediately
    setPlaceSelected(false); // Reset place selection state
  };

  // Prevent committing typed values (only commit selected places)
  const handleInputChange = e => {
    setInputValue(e.target.value); // Allow typing and display input value
    setPlaceSelected(false); // Reset place selection, indicating no valid place has been selected yet
  };

  // Ensure that clearing/backspacing clears the form value
  const handleBlur = () => {
    input.onBlur(); // Trigger blur event
    if (!placeSelected) {
      onChange(''); // Clear form state if no valid place was selected
      input.onChange(''); // Ensure the form state is cleared if the input is cleared
    }
  };

  const hasError = (touched && error) || (!inputValue && touched);

  return (
    <div className={css.container}>
      {/* Label for the input */}
      <label>{label}</label>
      <ReactGoogleAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        id={id}
        value={inputValue}
        onPlaceSelected={handlePlaceSelected} // Handle Google place selection
        onChange={handleInputChange} // Allow typing, but don't commit the value
        onFocus={input.onFocus} // Trigger focus event
        onBlur={handleBlur} // Handle blur to check if a valid place was selected
        placeholder={placeholder} // Use passed placeholder
        options={options} // Use passed options (default to cities)
        className={css.input}
      />
      {placeSelected && ( // Show the clear button only when a place has been selected
        <button
          className={css.clearButton} // CSS class for the button
          type="button"
          onClick={handleClear}
        >
          Clear
        </button>
      )}

      {/* Show the validation error below the input */}
      {hasError && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

export default GoogleMapsAutocompleteInput;
